@import "src/styles/common";

.title {
  width: 100%;
  margin: 0 0 9px 0;
  @include font(regular, h1);
  letter-spacing: -4.6px;
  text-align: left;
}

.subtitle {
  width: 100%;
  margin: 0 0 33px 0;
  @include font(regular, p3);
  text-align: left;
}
